import { Card, CardContent, CardHeader, Chip, Grid, Paper, Stack, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useMemo, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import Detail from './Detail'
import SoftTypography from 'components/SoftTypography'
import ServerSideTable from 'myComponent/Table/ServerSideTable'
import { postDataWithResponse } from 'global/postFunc'
import { getDataWithResponse } from 'global/getFunc'

const Pemilih = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
        </Routes>
    )
}

const Data = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [tableData, setTableData] = useState([{},{}]);
    const [rowCount, setRowCount] = useState(0);

    const [loading, setLoading] = useState(true)

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10, //customize the default page size
    });
    // const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilter, setColumnFilter] = useState([])

    const [listKecs, setListKecs] = useState([])
    const [listKels, setListKels] = useState([])
    const [listVg, setListVg] = useState([])

    useEffect(() => {
        getKec()
        getSumberData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSumberData = async () => {
        addReq()
        getDataWithResponse(
            '/admin/pemilih/sumber-data',
            decryptedUser,
            (res) => {
                setListVg(res)
            },
            (res) => {
                console.log(res);
            }
        );
    }

    const getKec = async () => {
        addReq();
        const kec = await axios.get(serverUrl + '/kecamatan');
        setListKecs(kec.data)
    }

    useEffect(() => {
        let object = columnFilter.find(obj => {
            return obj.id === 'id_kec'
        })
        
        if (object !== undefined) {
            console.log(object);
            
            getDesa(object.value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter])

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setListKels(data.data)
        console.log(data);
    }

    useEffect(() => {
        console.log(columnFilter);
        
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilter, pagination])

    const requestData = () => {
        const frm = {
            pagination: pagination,
            columnFilter: columnFilter
        };
        setLoading(true)
        addReq()
        postDataWithResponse(
            '/admin/pemilih-serverside',
            frm,
            decryptedUser,
            (response) => {
                console.log(response);
                setLoading(false)
                setTableData(response.data)
                setRowCount(response.rowCount)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }

    const [listKecFilter, setListKecFilter] = useState([])
    const [listKelFilter, setListKelFilter] = useState([])
    const [listVgFilter, setListVgFilter] = useState([])

    useEffect(() => {
        setListKecFilter([])
        listKecs.forEach((item, index) => {
            let x = { label: item.nama_kec, value: item.id_kec };
            setListKecFilter(listKecFilter => [...listKecFilter, x])
        })
    }, [listKecs])
    
    useEffect(() => {
        setListKelFilter([])
        listKels.forEach((item, index) => {
            let x = { label: item.nama_desa, value: item.id_desa };
            setListKelFilter(listKelFilter => [...listKelFilter, x])
        })
        
    }, [listKels])

    useEffect(() => {
        setListVgFilter([])
        listVg.forEach((item, index) => {
            let x = { label: item.sumber_data_nama, value: item.sumber_data_username };
            setListVgFilter(listVgFilter => [...listVgFilter, x])
        })
    }, [listVg])

    const columns = useMemo(() => [
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama',
            Cell: ({ cell }) => {
                switch (cell.row.original.tim) {
                    case 'Korcam':
                        return <Chip label={cell.row.original.nama} color='primary' />

                    case 'Relawan':
                        return <Chip label={cell.row.original.nama} color='success' />

                    case 'Vote Getter':
                        return <Chip label={cell.row.original.nama} color='warning' />

                    default:
                        switch (cell.row.original.status) {
                            case 'DTD':
                                return cell.row.original.nama

                            default:
                                return <Typography fontSize='small' variant='body2' color='red'>{cell.row.original.nama}</Typography>
                        }
                }
            }
        },
        {
            accessorKey: 'id_kec',
            header: 'Kec',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.nama_kec}</Typography>
            ),
            filterSelectOptions: listKecFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'id_kel',
            header: 'Kel/Desa',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.nama_kel}</Typography>
            ),
            filterSelectOptions: listKelFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'sumber_data_username',
            header: 'Sumber Data',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.sumber_data_nama}</Typography>
            ),
            filterSelectOptions: listVgFilter,
            filterVariant: 'select',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({ cell }) => (
                <Typography fontSize='small' variant='body2'>{cell.row.original.status}</Typography>
            ),
            filterVariant: 'select',
            filterSelectOptions: [
                {id: 'status', value: 'TIM'},
                {id: 'status', value: 'DTD'},
                {id: 'status', value: 'Suara Tambahan'},
            ],
        },
        {
            accessorKey: 'tempat_lahir',
            header: 'Tmp Lahir'
        },
        {
            accessorKey: 'tgl_lahir',
            header: 'Tgl Lahir'
        }
    ], [listKecFilter, listKelFilter, listVgFilter])

    const columnVisibility = {
        no_kk: false,
        // id_kel: false,
        tempat_lahir: false,
        tgl_lahir: false,
        tim: false,
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={rowCount}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography color='text'>
                                    Tabel Pemilih
                                </SoftTypography>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <SoftBox mb={1} ml={0.5}>
                                        <Typography component="label" variant="body2" fontWeight="bold">
                                            Ket
                                        </Typography>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        <Chip label="Korcam" color="primary" />
                                        <Chip label="Relawan Garda" color="success" />
                                        <Chip label="Vote Getter" color="warning" />
                                        <Typography fontSize='small' variant='body2' color='red'>Suara Tambahan</Typography>
                                        <Typography fontSize='small' variant='body2' >DTD</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" mt={2}>
                                <Grid item xs={12} component={Paper}>
                                    {/* <MyTable
                                        data={tableData ?? []}
                                        columns={columns}
                                        columnVisibility={columnVisibility}
                                        detailUrl={'/detail'}
                                        state={{ isLoading: loading }}
                                    /> */}
                                    <ServerSideTable
                                        loading={loading}
                                        data={tableData}
                                        columns={columns}
                                        rowCount={rowCount}
                                        baseUrl="/admin/pemilih"
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columnFilter={columnFilter}
                                        setColumnFilter={setColumnFilter}
                                        columnVisibility={columnVisibility}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Pemilih